<template>
  <div>
<van-cell @click="show = true">
  <div style="display: flex; justify-content: space-between;">
    <span>选择日期范围</span>
    <span>{{date}}</span>
  </div>
</van-cell>
<van-calendar v-model="show" type="range" @confirm="onConfirm"  confirm-text='查询' :minDate='new Date(2010, 0, 1)'/>

    <van-tree-select height="auto" :items="items" :main-active-index.sync="activeIndex" :active-id.sync="activeId">
  <template #content>
    <div style="padding-top: 1rem">
    <div class="van-coupon">
     <div class="van-item van-item-title">{{items[activeIndex].text}}</div>
    </div>

<!--       <div class='van-item'>
        <span>{{item.title}}</span>
        <span>{{item.value}}</span>
      </div> -->
    <div class="van-coupon">
     <div class="van-item van-item-title">基本信息</div>
     <div class='van-item' v-for='(item,key) in items[activeIndex].infos'>
            <span>{{item.title}}</span>
            <span>{{item.value}}</span>
      </div>
    </div>
    <div class="van-coupon">
     <div class="van-item van-item-title">回收概况</div>
     <div class='van-item' v-for='(item,key) in items[activeIndex].huishou'>
            <span>{{item.title}}</span>
            <span>{{item.value}}</span>
      </div>
    </div>
      <div class="van-coupon">
     <div class="van-item van-item-title">消费统计</div>
     <div class='van-item' v-for='(item,key) in items[activeIndex].mall'>
            <span>{{item.title}}</span>
            <span>{{item.value}}</span>
      </div>
    </div>
</div>

  </template>
</van-tree-select>
  </div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'huishou_sum',
  // props:[points],
  data() {
    return {
      dataValue:[],
      date: '不限制',
      show: false,
      activeId: 1,
      activeIndex: 0,
      items: [
        {
          text:'',
          infos:[],
          huishou:[],
          mall:[]
        }
      ]
    }
  },
  mounted () {
    this.getItems()
  },
  watch:
  {
  },
  methods:{
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      console.log(date)
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} ~ ${this.formatDate(end)}`
      this.dataValue = [this.formatDate(start),this.formatDate(end)]
    },
    getItems:function()
    {
      this.loading = true
      ajax.get('/base/getHuishouSum',{}).then(res=>{
        this.loading = false
        this.items = res
      }).catch(e=>{
        this.$toast(e.msg)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.goods{padding:5px 0;}
.goods_title{
  font-size:12px;color:#000;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bt{
  float: right;
}
.van-panel__content{padding:10px 16px!important;}
.van-coupon{
  box-shadow:  0 0 10px rgba(0, 0, 0, 0.25) !important
}
.van-item{
  display:flex;
  justify-content: space-between;
  padding: 0px 3px;
  line-height: 1.6rem;
}
.van-item-title{
  line-height: 2rem;
  border-bottom: 1px solid #E1E1E1;
}
</style>
